import { Link } from "gatsby-plugin-react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";

const DropdownLinks = ({ PagesGroupName, PageLinks, linkUrl }) => {
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const [show, setShow] = useState(false);

  const content = useRef(null);

  const { pathname } = useLocation();

  const linkItem = useRef(null);

  // function toggleAccordion() {
  //   setHeight(
  //     active === "-active" ? "0px" : `${content.current.scrollHeight}px`
  //   );
  // }

  function showDropDown() {
    setShow(!show);
  }

  return (
    <div className="m-nav__dropdown">
      <span
        ref={linkItem}
        // onClick={toggleAccordion}
        className={`${pathname.includes(linkUrl) ? "active-link" : ""}`}
      >
        {PagesGroupName}{" "}
        <span
          className={show ? "-plus -show" : "-plus"}
          onClick={showDropDown}
        ></span>
      </span>
      <div
        ref={content}
        // style={{ maxHeight: `${height}` }}
        className={
          show ? "m-nav__dropdown--list -show" : "m-nav__dropdown--list"
        }
      >
        {PageLinks.map(({ linkText, linkUrl }, key) => (
          <Link to={linkUrl} key={key} activeClassName="active-sublink">
            {linkText}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DropdownLinks;
