import React, { useState, useEffect } from 'react'

export const ModalContext = React.createContext()


const withModalContext = (WrappedComponent) => (props) => {
    const [openModal, setOpenModal] = useState(false)

    return (
        <ModalContext.Provider value={{
            openModal,
            setOpenModal
        }}>
            <WrappedComponent {...props} />
        </ModalContext.Provider>
    )
}

export default withModalContext;