import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Favicon from "../../assets/images/global/favicon.png";
import { useLocation } from "@reach/router";

const SEO = ({ title, description, image }) => {
  const { site } = useStaticQuery(query);
  const { pathname } = useLocation();
  // const { siteTitle, siteDescription } = site.siteMetadata;
  const { defaultTitle, defaultDescription, siteUrl, defaultImage } =
    site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };
  return (
    <Helmet
      title={`${title} — ${defaultTitle}`}
      // titleTemplate={`%s — ${defaultTitle}`}
      htmlAttributes={{
        lang: "en",
      }}
      link={[{ rel: "shortcut icon", type: "image/png", href: `${Favicon}` }]}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <script type="text/javascript">
        {`var head = document.getElementsByTagName('head')[0];

    var insertBefore = head.insertBefore;

    head.insertBefore = function (newElement, referenceElement) {

        if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {

            return;
        }

        insertBefore.call(head, newElement, referenceElement);
    };`}
      </script>
      {/* <script src="https://tlk.io/embed.js" type="text/javascript" /> */}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
      }
    }
  }
`;
