import React, { Fragment, useState, useEffect } from "react";
import Logo from "../global/logo";
import IconPaperclip from "../../assets/images/global/paperclip.svg";
import { Link } from "gatsby-plugin-react-i18next";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "react-step-progress-bar";

const isNullOrWhitespace = (value) => {
  if (!value || value.trim() === "") {
    return true;
  }
  return false;
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const Contact = ({ setOpenModal }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");
  const [phone, setPhone] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const formValues = { name, company, email, topic, message, phone };

  const [isFocus, setIsFocus] = useState(false);
  const [isBlur, setIsBlur] = useState(false);

  const handleNextClick = () => {
    if (
      isNullOrWhitespace(name) ||
      isNullOrWhitespace(email) ||
      !RegExp(emailRegex).test(email)
    ) {
      setHasError(true);
    } else {
      setStep(step + 1);
    }
  };

  const disabled = isNullOrWhitespace(name) || isNullOrWhitespace(email);

  const API_PATH = "/api/contact/index.php";

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleNextClick();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: formValues,
    })
      .then((result) => {
        setMailSent(result.data.sent);
      })
      .catch((error) => setError(error.message));
  };
  const progressBar = () => {
    switch (step) {
      case 1:
        return <ProgressBar percent={(33, 3333)} />;
      case 2:
        return <ProgressBar percent={(33, 3333 * 2)} />;
      case 3:
        return <ProgressBar percent={(33, 33333 * 3)} />;
      default:
        return;
    }
  };

  useEffect(() => {
    // console.log(document.querySelectorAll(".js-form input"));
    const inputs = document.querySelectorAll(".js-form input");

    inputs.forEach((input) => {
      if (input.value !== "") {
        input.classList.add("-focus");
      }
    });
  });

  const focusHandler = (event) => {
    event.target.classList.add("-focus");
  };

  const blurHandler = (event) => {
    if (event.target.value === "") {
      event.target.classList.remove("-focus");
    } else {
      event.target.classList.add("-focus");
      // console.log("ime", name);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Fragment>
            <h2 className="m-contact__heading">{t("contact.reachYou")}</h2>
            <div className="m-contact__form js-form">
              <div className="m-contact__inputGroup -name">
                <input
                  type="text"
                  value={name}
                  onFocus={focusHandler}
                  onBlur={blurHandler}
                  onChange={(e) => setName(e.target.value)}
                  className="input"
                />
                <label>{t("contact.yourName")}</label>
                {hasError && isNullOrWhitespace(name) && (
                  <span className="m-contact__inputGroup--error">
                    {t("contact.errorName")}
                  </span>
                )}
              </div>
              <div className="m-contact__inputGroup">
                <input
                  type="text"
                  onFocus={focusHandler}
                  onBlur={blurHandler}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <label>{t("contact.yourCompany")}</label>
              </div>
              <div className="m-contact__inputGroup">
                <input
                  type="text"
                  value={phone}
                  onFocus={focusHandler}
                  onBlur={blurHandler}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label>{t("contact.yourPhone")}</label>
              </div>
              <div className="m-contact__inputGroup -name">
                <input
                  type="email"
                  value={email}
                  onFocus={focusHandler}
                  onBlur={blurHandler}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label>{t("contact.yourEmail")}</label>

                {hasError &&
                  (isNullOrWhitespace(email) ||
                    !RegExp(emailRegex).test(email)) && (
                    <span className="m-contact__inputGroup--error">
                      {t("contact.errorEmail")}
                    </span>
                  )}
              </div>
            </div>
          </Fragment>
        );

      case 2:
        return (
          <Fragment>
            <h2 className="m-contact__heading">{t("contact.discuss")}</h2>
            <div className="m-contact__form">
              <div className="m-contact__radioGroup">
                <input
                  type="radio"
                  id="developApp"
                  name="topic"
                  value="Develop an application"
                  onChange={(e) => setTopic(e.target.value)}
                />
                <label htmlFor="developApp">{t("contact.developApp")}</label>
              </div>
              <div className="m-contact__radioGroup">
                <input
                  type="radio"
                  id="hireTeam"
                  name="topic"
                  value="Hire a team"
                  onChange={(e) => setTopic(e.target.value)}
                />
                <label htmlFor="hireTeam">{t("contact.hireTeam")}</label>
              </div>
              <div className="m-contact__radioGroup">
                <input
                  type="radio"
                  id="digitalisation"
                  name="topic"
                  value="Digitalisation within my company"
                  onChange={(e) => setTopic(e.target.value)}
                />
                <label htmlFor="digitalisation">
                  {t("contact.digitalisation")}
                </label>
              </div>
              <div className="m-contact__radioGroup">
                <input
                  type="radio"
                  id="reeng"
                  name="topic"
                  value="Re-engineering of my application"
                  onChange={(e) => setTopic(e.target.value)}
                />
                <label htmlFor="reeng">{t("contact.reEng")}</label>
              </div>
              <div className="m-contact__radioGroup">
                <input
                  type="radio"
                  id="otherServices"
                  name="topic"
                  value="It Services"
                  onChange={(e) => setTopic(e.target.value)}
                />
                <label htmlFor="otherServices">{t("contact.services")}</label>
              </div>
              <div className="m-contact__radioGroup">
                <input
                  type="radio"
                  id="other"
                  name="topic"
                  value="Other"
                  onChange={(e) => setTopic(e.target.value)}
                />
                <label htmlFor="other">{t("contact.other")}</label>
              </div>
            </div>
          </Fragment>
        );

      case 3:
        return (
          <Fragment>
            <h2 className="m-contact__heading">{t("contact.project")}</h2>
            <div className="m-contact__form">
              <div className="m-contact__inputGroup">
                <textarea
                  placeholder={t("contact.requirements")}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              {/* <div className="m-contact__inputGroup">
                                <label htmlFor="attach-file" className="m-contact__inputGroup--fileLabel">
                                    {file ? <span>{file}</span> : "ATTACH A DOCUMENT"}
                                    <IconPaperclip />
                                </label>
                                <input id="attach-file" type="file" className="m-contact__inputGroup--fileInput" onChange={(e) => setFile(e.target.value)} />
                            </div> */}
            </div>
          </Fragment>
        );

      case 4:
        return (
          <Fragment>
            <div className="m-contact__finishedSteps">
              <h2 className="-bigHeader">{t("contact.thankYou")}</h2>
              <p>{t("contact.reciveInfo")}</p>
              <Link
                to="/"
                className="a-btn"
                onClick={() => setOpenModal(false)}
              >
                {t("contact.backToHome")}
              </Link>
            </div>
          </Fragment>
        );
      default:
        return;
    }
  };

  return (
    <div className={`m-contact ${step === 4 ? "-lastStep" : ""}`}>
      <div className="m-contact__nav">
        <div className="_wr">
          <div className="_w">
            <div
              className="m-contact__nav--logo"
              onClick={() => setOpenModal(false)}
            >
              <Logo />
            </div>
          </div>
        </div>
      </div>
      <div
        className="m-contact__close"
        onClick={() => setOpenModal(false)}
      ></div>
      <div className="_wr">
        <div className="m-contact__back _w">
          {step >= 2 && step < 4 && (
            <span
              className="a-btn -back"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <a className="a-arrow -left"></a> Back to step {step - 1}
            </span>
          )}
          {step < 2 && (
            <Link
              to="/"
              className="a-btn -back"
              onClick={() => setOpenModal(false)}
            >
              <span className="a-arrow -left"></span>
              {t("contact.backToHome")}
            </Link>
          )}
        </div>
      </div>
      <div className="_wr">
        <div className="m-contact__content _w">
          <div className="m-contact__step">{renderStep()}</div>
        </div>
      </div>
      {step < 4 && (
        <div className="m-progressBar">
          <div className="_wr">
            <div className="_w">
              <div className="m-progressBar__progress">
                <p>
                  <span>Step {step}</span> of 3
                </p>
                <div className="m-progressBar__line">{progressBar()}</div>
              </div>
              <div className="m-contact__arrows">
                {step <= 2 && (
                  <button
                    disabled={disabled}
                    className={`a-btn -contact ${disabled ? "-disabled" : ""}`}
                    onClick={handleNextClick}
                  >
                    {t("contact.next")}
                  </button>
                )}
                {step === 3 && (
                  <button
                    type="submit"
                    className="a-btn -contact"
                    onClick={(e) => handleFormSubmit(e)}
                  >
                    {t("contact.submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
