import React from "react";
import Logo from "../../assets/images/global/logo.svg";
import { Link } from "gatsby-plugin-react-i18next";

const Contact = () => {
  return (
    <Link to="/" aria-label="Ewasoft logo" title="Ewasoft logo">
      <Logo />
    </Link>
  );
};

export default Contact;
