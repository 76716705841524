import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import Logo from "../global/logo";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import withModalContext from "../../context/ModalContext";
import DropdownLinks from "./dropdown-links";

const Navigation = ({ openModal, setOpenModal }) => {
  // const {openModal, setOpenModal} = useContext(ModalContext)
  const { languages, originalPath, i18n } = useI18next();
  const { t } = useTranslation();

  const [mobile, setMobile] = useState(false);
  const [scroll] = useState(false);

  const handleClick = () => setMobile(!mobile);

  // const onScroll = () => {
  //   setScroll(window.scrollY > 50);
  //   setMobile(false);
  // };

  useEffect(() => {
    // onScroll();
    // window.addEventListener("scroll", onScroll, true);
    window.addEventListener("resize", () => setMobile(false));

    return () => {
      // window.removeEventListener("scroll", onScroll, true);
      window.removeEventListener("resize", () => setMobile(false));
    };
  }, []);

  const displayLanguageName = (language) => {
    if (language === "en") {
      return "English";
    }
    if (language === "de") {
      return "Deutsch";
    }
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const marketsLinks = [
    {
      linkText: `${t("markets.eCommerce")}`,
      linkUrl: "/markets/e-commerce",
    },
    {
      linkText: `${t("markets.marketing")}`,
      linkUrl: "/markets/marketing",
    },
    {
      linkText: `${t("markets.industrials")}`,
      linkUrl: "/markets/industrials",
    },
    {
      linkText: `${t("markets.realEstate")}`,
      linkUrl: "/markets/real-estate",
    },
  ];
  const servicesLinks = [
    {
      linkText: `${t("navigation.services.appDev")}`,
      linkUrl: "/it-services/application-development",
    },
    {
      linkText: `${t("navigation.services.engTeam")}`,
      linkUrl: "/it-services/engineering-team",
    },
    {
      linkText: `${t("navigation.services.reEng")}`,
      linkUrl: "/it-services/application-re-engineering",
    },
    {
      linkText: `${t("navigation.services.semantic")}`,
      linkUrl: "/it-services/semantic-ai-data-platform",
    },
    {
      linkText: `${t("navigation.services.blockchain")}`,
      linkUrl: "/it-services/blockchain-development",
    },
    {
      linkText: `${t("navigation.services.otherServices")}`,
      linkUrl: "/it-services/other-services",
    },
  ];

  //assigning location variable
  const uselocation = useLocation();

  //destructuring pathname from location
  const { pathname } = uselocation;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  // console.log(splitLocation[1], "split");

  return (
    <nav
      className={`m-nav ${scroll ? "-scrolled" : ""} ${
        mobile ? "-mobile" : ""
      }`}
    >
      <div className="_w"></div>
      <div className="m-nav__content">
        <div className="_wr">
          <div className="m-nav__contentContainer">
            <div className="m-nav__logo">
              <Logo />
            </div>
            <div className="m-nav__right">
              <div className="m-nav__links">
                <div className="m-nav__links--inner">
                  <Link to="/" activeClassName="active-link">
                    {t("navigation.home")}
                  </Link>
                  <DropdownLinks
                    PagesGroupName={t("markets.markets")}
                    PageLinks={marketsLinks}
                    linkUrl="/markets"
                  />
                  <DropdownLinks
                    PagesGroupName={t("navigation.services.itServices")}
                    PageLinks={servicesLinks}
                    linkUrl="/it-services"
                  />
                  {/* <div className="m-nav__dropdown">
                  <span>{t('navigation.products.products')}</span>
                  <div className="m-nav__dropdown--list">
                    <Link to="#" >{t('navigation.products.fLeader')}</Link>
                    <Link to="#" >{t('navigation.products.eCommerce')}</Link>
                  </div>
                </div> */}
                  <Link to="/our-work" activeClassName="active-link">
                    {t("navigation.ourWork")}
                  </Link>
                  <Link
                    to="/about-us"
                    activeClassName="active-link"
                    className="-lastLink"
                  >
                    {t("navigation.aboutUs")}
                  </Link>
                  {/* <Link
                    to="/blog"
                    className={
                      splitLocation[1] === "blog"
                        ? "active-link -lastLink"
                        : "-lastLink"
                    }
                  >
                    {t("navigation.blog")}
                  </Link> */}
                </div>
                <button
                  className="a-btn -blackBorder a-hover -effectThree -nav"
                  onClick={handleOpenModal}
                >
                  {t("buttons.scheduleCall")}
                </button>
              </div>
              <div className="m-nav__lang">
                {languages.map((lng) => (
                  <Link
                    to={originalPath}
                    language={lng}
                    className={`${lng} ${
                      lng === i18n.language ? "-active" : ""
                    }`}
                    key={lng}
                    title={displayLanguageName(lng)}
                  >
                    {lng}
                  </Link>
                ))}
              </div>
              <div
                className="m-nav__hamburger"
                onClick={handleClick}
                onKeyPress={handleClick}
                role="menu"
                tabIndex={0}
              >
                <span role="menuitem" aria-label="Responsive menu item"></span>
                <span role="menuitem" aria-label="Responsive menu item"></span>
                <span role="menuitem" aria-label="Responsive menu item"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withModalContext(Navigation);
